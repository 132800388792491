import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import { useDispatch } from "react-redux";
import SidebarNameSection from "../components/SidebarNameSection";
import OptionsSelectionSection from "../components/OptionsSelectionSection";
import SidebarFieldPreferenceSection from "../components/SidebarFieldPreferenceSection";
import SidebarLabelPreferenceSection from "../components/SidebarLabelPreferenceSection";

const InputText = withStyles((theme) => ({
  input: {
    color: "#091540",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #ABB3BF",
    fontSize: 10,
    paddingLeft: 2,
    transition: theme?.transitions.create(["border-color", "box-shadow"]),
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {},
  sideHeading: {
    color: "#091540",
    fontWeight: 600,
    fontSize: 13,
    padding: "10px 5px 0px 5px",
    display: "flex",
    justifyContent: "space-between",
    "& span": {
      textAlign: "right",
      "& input": {
        paddingLeft: 10,
      },
    },
  },
  section: {
    padding: 10,
  },
  sectionTitle: {
    color: "#999",
    fontSize: 12,
  },
  sectionLabel: {
    color: "#999",
    fontSize: 10,
    marginRight: 5,
    marginTop: 5,
  },
  fullWidthText: {
    margin: "10px 0",
  },
  input: {
    color: "#091540",
    fontSize: 10,
  },
  center: {
    textAlign: "center",
  },
}));

export default function FileUploadSidebar(props) {
  const classes = useStyles();

  // const { style, id, type, parent: container, index } = props;
  const {
    id,
    itemRef,
    style,
    values,
    name,
    title,
    type: itemType,
    dataType,
    showStyling,
  } = props;
  const dispatch = useDispatch();
  const [isDynamic, setIsDynamic] = useState(name?.startsWith("@"));

  return (
    <div className="sidebar-container">
      <SidebarNameSection
        itemId={id}
        itemType={itemType}
        name={name}
        title={title}
        itemRef={itemRef}
        isDynamic={isDynamic}
        setIsDynamic={setIsDynamic}
      />

      <div className="sidebar-container-scroll">
        <SidebarFieldPreferenceSection
          itemType={itemType}
          name={name}
          title={title}
          itemRef={itemRef}
          values={{ ...values }}
          dataType={dataType}
          isDynamic={isDynamic}
          setIsDynamic={setIsDynamic}
        />
        {!isDynamic && (
          <OptionsSelectionSection
            itemType={itemType}
            name={name}
            title={title}
            itemRef={itemRef}
            values={{ ...values }}
            dataType={dataType}
          />
        )}
        <SidebarLabelPreferenceSection
          itemType={itemType}
          name={name}
          title={title}
          itemRef={itemRef}
          values={values}
          dataType={dataType}
        />
      </div>
    </div>
  );
}
